<template>
  <div class="demo">
    <div id="lineEchartsLine" class="chartBar" :class="{echartNoData:!hasFlag&&!showCharts}"></div>
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import { GET_SEVEN_PMS } from "@/api";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
  symbolSize_Font,
  lineSize,
  map_font_color,
  symbolSize
} from "./font";
import echarts from "echarts";
export default {
  props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  components: {
    CountTo,
  },
  data() {
    return {
      sevenPms: {
        dayRented: [11, 22, 53, 43, 56, 26, 71],
        // dayPMS: [],
        day: [1, 2, 3, 4, 5, 6, 7],
      },
      hasFlag: true,
      showCharts:false
    };
  },
  mounted() {
    this.lineEchartsLine = this.$echarts.init(
      document.getElementById("lineEchartsLine")
    );
    window.addEventListener("resize", () => {
      this.lineEchartsLine.resize();
    });
    this.getSevenPms();
    this.daylineInter = null;
    this.daylineInter = setInterval(() => {
      this.getSevenPms();
    }, 3600000);
  },

  beforeDestroy() {
    clearInterval(this.daylineInter);
  },
  methods: {
    resize() {
      this.lineEchartsLine.resize();
    },
    getOptions(data) {
      return {
        title: {
          text: "最近7天入住率",
          textStyle: {
            align: "left", //字体居中
            color: "#fff", //字体颜色
            fontSize: title2_Font(), //字体大小
          },
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            lineHeight: h_Font(),
            fontSize: x_Font(),
          },
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "5%",
          top: title2_Font() * 3,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: data.day, //['1','2','3','4','5','6' ],
          axisLabel: {
            color: "#fff",
            fontSize: x_Font(), //字体大小
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: lineSize(),
            },
          },

          axisLabel: {
            fontSize: x_Font(), //字体大小
            color: "#fff",
          },
        },
        series: [
          {
            name: "入住率",
            type: "line",
            smooth: true,
            symbolSize: symbolSize_Font(),
            lineStyle: {
              width: line_Font(), //设置线条粗细
            },
            data: data.dayRented,
            itemStyle: {
              color: "#409eff",
              fontSize: x_Font(),
            },
              markPoint: {
              symbolSize: symbolSize(),
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    fontSize: x_Font(),
                  },
                },
              },
              data: [
                {
                  type: "max",
                  name: "最大值",
                },
                { type: "min", name: "最小值" },
              ],
            },
            areaStyle: {
              //区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(46,158,255,1)",
                    },
                    {
                      offset: 0.6,
                      color:  "rgba(46,158,255,0.3)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(46,158,255,0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(46,158,255, 0.1)",
                shadowBlur: 6,
              },
            },
          },
        ],
      };
    },
    // 获取七日内的pms 出租率情况
    async getSevenPms(data = { roleType: this.roleType }) {
      try {
        this.hasFlag = true;

        let res = await GET_SEVEN_PMS(data);

        if (!res.data.data || res.data.data.length == 0) {
          // this.hasFlag = false;
        } else {
          this.showCharts=true;
          ["dayRented", "dayPMS", "day"].forEach((item) => {
            this.$set(this.sevenPms, item, []);
          });
          res.data.data.forEach((item) => {
            this.sevenPms.dayRented.unshift(item.deviceRentedCount);
            // this.sevenPms.dayPMS.unshift(item.occupancyRate);
            this.sevenPms.day.unshift(item.createTime);
          });
        }
      } catch (error) {}

      if (this.hasFlag&&this.showCharts) {
        
        this.initLine();
      }

      // this.initLine();
      // this.sevenPms = res.data.data
    },
    initLine() {
      this.lineEchartsLine.setOption(this.getOptions(this.sevenPms));
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
}

</style>